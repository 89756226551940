@function px2vw($n) { 
  @return ($n/7.5)+vw;
}
@media screen and (max-width:750px) {
  .common-title {
    height: px2vw(65);
    line-height: px2vw(65);
    font-size: px2vw(48);
  }
  body.en-US {
    .page-index .banner .banner-content {
      padding-top: px2vw(58);
      padding-left: px2vw(60);
    }
    .page-index .banner .banner-content .tip-wrap h1 {
      width: px2vw(630);
      height: px2vw(108);
      line-height: px2vw(54);
      font-size: px2vw(44);
      margin-bottom: px2vw(32);
    }
    .page-index .banner .banner-content .tip-wrap h2 {
      font-size: px2vw(32);
      line-height: px2vw(44);
      width: px2vw(288);
      height: auto;
    }
    .page-index .banner .banner-content .big-logo {
      left: px2vw(361);
      top: px2vw(193);
    }
    .page-index .banner .banner-content .tip-wrap .white-paper-btn {
      margin-top: px2vw(34);
    }
    .page-index .credible .credible-content .credible-l .line-text .btn-group {
      display: block;
      .btn {
        display: block;
        width: px2vw(388);
        height: px2vw(60);
        margin-bottom: px2vw(20);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .page-index .m-advantage .slider-item {
      width: px2vw(540);
      height: px2vw(870);
      p {
        max-width: px2vw(380);
        margin: px2vw(40) auto 0;
        height: auto;
      }
    }
    .page-index .business .business-content ul {
      padding: 0 px2vw(46);
    }
    .page-index .banner {
      height: px2vw(1338);
    }
    .page-index .digital {
      top: px2vw(648);
    }
    .page-index .m-advantage .slider-l {
      top: px2vw(516);
    }
    .page-index .m-advantage .slider-r {
      top: px2vw(516);
    }
    .page-index .global-cny .global-cny-wrap .common-title {
      max-width: px2vw(520);
      height: px2vw(130);
      line-height: px2vw(65);
      margin: 0 auto;
    }
    .page-index .global-cny .global-cny-wrap .global-cny-content {
      padding-top: px2vw(50);
    }
  }
  .page-index {
    position: relative;
    .header {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 999999;
      box-shadow: 0 1px 10px 0 rgba(198, 198, 198, 0.2);
      .header-wrap {
        height: px2vw(100);
        display: flex;
        justify-content: space-between;
        
        .menu-wrap {
          display: flex;
          align-items: center;
          .logo {
            width: px2vw(157);
            margin-left: px2vw(32);
            margin-right: px2vw(31);
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
          .menu-group {
            display: flex;
            .item {
              margin-right: 16px;
              height: px2vw(100);
              line-height: px2vw(100);
              font-size: px2vw(26);
              white-space: nowrap;
              a {
                color: #182230;
                &.more {
                  color: #14c387;
                }
              }
            }
          }
        }
        .muti-lang {
          padding: px2vw(19) 0;
          margin-right: px2vw(31);
          position: relative;
          .lang-wrap {
            height: px2vw(62);
            line-height: px2vw(62);
            display: flex;
            align-items: center;
            color: #182230;
            font-size: px2vw(26);
            cursor: pointer;
            .lang-icon {
              max-width: px2vw(43);
              max-height: px2vw(43);
              margin-right: px2vw(13);
            }
            .lang-select-icon {
              max-width: px2vw(36);
              max-height: px2vw(36);
              margin-left: px2vw(20);
            }
          }
          ul {
            position: absolute;
            top: px2vw(81);
            left: px2vw(-26);
            border-radius: px2vw(4);
            box-shadow: 0 0 px2vw(20) 0 #ededed;
            background-color: #ffffff;
            display: none;
            li {
              height: px2vw(90);
              line-height: px2vw(90);
              font-size: px2vw(26);
              cursor: pointer;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              justify-content: flex-start;
              padding-left: px2vw(26);
              padding-right: px2vw(33);
              img {
                display: flex;
                width: px2vw(43);
                height: px2vw(43);
                margin-right: px2vw(8);
              }
              &:hover {
                background-color: #f8f8f9;
              }
            }
          }
        }
      }
    }
    .banner {
      background-size: px2vw(1500) auto;
      position: relative;
      height: px2vw(1238);
      background-color: #F9F9FA;
     .banner-content {
       padding: px2vw(30) 0 px2vw(25) px2vw(85);
       .tip-wrap {
         max-width: 630px;
         h1 {
          font-size: px2vw(54);
          color: #182230;
          font-weight: normal;
          line-height: px2vw(74);
          height: px2vw(74);
          margin-bottom: px2vw(20);
         }
         h2 {
          font-size: px2vw(38);
          color: #182230;
          font-weight: normal;
          line-height: px2vw(52);
          width: px2vw(232);
         }
         .white-paper-btn {
          width: fit-content;
          white-space: nowrap;
          padding: 0 px2vw(32);
          height: px2vw(60);
          border-radius: 4px;
          background-image: linear-gradient(81deg, #13948a -16%, #16f9a5 107%);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: px2vw(25);
          cursor: pointer;
          margin-top: px2vw(30);
          opacity: 0.9;
          transition: all 0.2s;
          a {
            color: #fff;
          }
          &:hover {
            opacity: 1;
          }
         }
       }
       .big-logo {
        position: absolute;
        width: px2vw(329);
        height: px2vw(268);
        left: px2vw(361);
        top: px2vw(133);
        .banner-logo {
         position: absolute;
         left: 0;
         top: 0;
         width: px2vw(329);
          height: px2vw(268);
        }
        .banner-coin-b {
         animation: b 1.5s ease-in-out 2s infinite alternate;
         position: absolute;
         width: px2vw(40);
         height: px2vw(41);
         left: px2vw(253);
         top: 0;
        }
        .banner-coin-t {
         animation: b 2.4s ease-in-out infinite alternate;
         position: absolute;
         width: px2vw(40);
         height: px2vw(40);
         left: px2vw(5);
         top: px2vw(81);
        }
        .banner-coin-eth {
         animation: b 2.4s ease-in-out 1.4s infinite alternate;
         position: absolute;
         width: px2vw(27);
         height: px2vw(29);
         left: px2vw(66);
         top: px2vw(146);
        }
        .banner-coin-purple {
         animation: b 1s ease-in-out 0.6s infinite alternate;
         position: absolute;
         width: px2vw(10);
         height: px2vw(9);
         left: px2vw(206);
         top: px2vw(12);
        }
        .banner-coin-green {
         animation: b 1.7s ease-in-out 1s infinite alternate;
         position: absolute;
         width: px2vw(10);
         height: px2vw(9);
         left: px2vw(14);
         top: px2vw(142);
        }
        .banner-coin-yellow {
         animation: b 3s ease-in-out 1.8s infinite alternate;
         position: absolute;
         width: px2vw(10);
         height: px2vw(9);
         left: px2vw(308);
         top: px2vw(55);
        }
      }
     } 
    }
    .digital {
      position: absolute;
      top: px2vw(560);
      width: 100%;
      .update-time {
        text-align: center;
        color: #333333;
        font-size: px2vw(24);
        height: px2vw(30);
        line-height: px2vw(30);
        margin-top: px2vw(15);
      }
      ul {
        margin-top: px2vw(40);
        li {
          display: flex;
          justify-content: flex-start;
          padding: px2vw(20) 0 px2vw(20) px2vw(50);
          width: px2vw(650);
          height: px2vw(180);
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          position: relative;
          margin: 0 auto px2vw(20);
          text-align: left;
          border-radius: px2vw(8);
          background-color: #ffffff;
          box-shadow: 0 px2vw(8) px2vw(25) 0 #f5f5f5;
          border: solid px2vw(0.8) #eaebee;
          background-color: #ffffff;

          .digital-m-circulation {
            margin-left: px2vw(37);
          }
          img {
            display: block;
            width: px2vw(140);
            height: px2vw(140);
          }
          p {
            color: #182230;
            font-size: px2vw(40);
            height: px2vw(55);
            line-height: px2vw(55);
            margin-top: px2vw(20);
          }
          span {
            display: block;
            margin-top: px2vw(9);
            height: px2vw(30);
            line-height: px2vw(30);
            color: #182230;
            font-size: px2vw(26);
          }
        }
      }
    }
    .why-choice {
      padding: px2vw(60) px2vw(40) 0;
      .choice-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .choice-l {
          margin-top: px2vw(26);
          .sub-title {
            height: px2vw(50);
            font-size: px2vw(36);
            line-height: px2vw(50);
            color: #182230;
            margin-bottom: px2vw(31);
          }
          p {
            margin-bottom: px2vw(28);
            padding-left: px2vw(52);
            max-width: 573px;
            box-sizing: border-box;
            position: relative;
            font-size: px2vw(26);
            line-height: px2vw(44);
            color: #657180;
            img {
              width: px2vw(30);
              height: px2vw(30);
              position: absolute;
              left: 0;
              top: px2vw(8);
            }
          }
        }
        .choice-r {
          margin-top: px2vw(50);
          width: px2vw(320);
          height: px2vw(320);
          position: relative;
          .opacity-c {
            position: absolute;
            width: 100%;
            height: 100%;
            left: px2vw(-30);
            top: px2vw(-9);
          }
          .opacity-pane {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .stable-value {
      margin-bottom: 64px;
      .stable-content {
        .stable-l {
          width: px2vw(320);
          height: px2vw(320);
          position: relative;
          margin: px2vw(51) auto px2vw(26);
          .stable-c {
            position: absolute;
            width: 100%;
            height: 100%;
            left: px2vw(0);
            top: px2vw(12);
          }
          .stable-pane {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
        .stable-r {
          width: px2vw(630);
          margin: 0 auto;
          .sub-title {
            height: px2vw(50);
            font-size: px2vw(36);
            line-height: px2vw(50);
            color: #182230;
            margin-bottom: px2vw(31);
          }
          p {
            margin-bottom: px2vw(28);
            padding-left: px2vw(52);
            box-sizing: border-box;
            position: relative;
            font-size: px2vw(26);
            line-height: px2vw(44);
            color: #657180;
            img {
              width: px2vw(30);
              height: px2vw(30);
              position: absolute;
              left: 0;
              top: px2vw(8);
            }
          }
        }
      }
    }
    .m-advantage {
      position: relative;
      .slider-l {
        position: absolute;
        width: px2vw(38);
        height: px2vw(38);
        left: px2vw(56);
        top: px2vw(400);
        z-index: 999;
      }
      .slider-r {
        position: absolute;
        width: px2vw(38);
        height: px2vw(38);
        right: px2vw(56);
        top: px2vw(400);
        z-index: 999;
      }
      padding: px2vw(80) px2vw(45) px2vw(100) px2vw(45);
        .swiper-slide-prev, .swiper-slide-next {
          .slider-item {
            box-shadow: 0 px2vw(10) px2vw(30) 0 #f8f8f9;
            background-color: #f8f8f9;
          }
        }
        .swiper-slide-active {
          .slider-item {
            box-shadow: 0 px2vw(8) px2vw(25) 0 #f5f5f5;
          }
        }
        .slider-item {
          padding-top: px2vw(60);
          width: px2vw(540);
          height: px2vw(700);
          margin: 0 auto;
          border-radius: px2vw(8);
          box-shadow: 0 px2vw(10) px2vw(30) 0 #f8f8f9;
          border: solid px2vw(1) #eaebee;
          background: #fff;
          box-sizing: border-box;
          img {
            display: block;
            margin: 0 auto;
            width: px2vw(140);
            height: px2vw(140);
          }
          p {
            text-align: center;
            color: #182230;
            font-size: px2vw(36);
            height: px2vw(50);
            line-height: px2vw(50);
            margin-top: px2vw(39);
          }
          span {
            text-align: left;
            display: block;
            line-height: px2vw(52);
            color: #657180;
            margin: px2vw(20) auto 0;
            font-size: px2vw(26);
            max-width: px2vw(400);
          }
        }
    }
    .credible {
      .credible-content {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        .credible-l {
          max-width: px2vw(630);
          .sub-title {
            height: px2vw(50);
            font-size: px2vw(36);
            line-height: px2vw(50);
            color: #182230;
            margin-bottom: px2vw(31);
          }
          .line-text {
            padding-left: px2vw(52);
            box-sizing: border-box;
            position: relative;
            font-size: px2vw(26);
            line-height: px2vw(44);
            color: #657180;
            a {
              color: #5f9afd;
            }
            .btn-group {
              display: flex;
              margin-top: px2vw(29);
              margin-bottom: px2vw(50);
              .btn {
                width: px2vw(252);
                height: px2vw(60);
                border-radius: 4px;
                background-image: linear-gradient(84deg, #13948a -16%, #16f9a5 108%);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: px2vw(20);
                cursor: pointer;
                opacity: 0.9;
                transition: all 0.2s;
                font-size: px2vw(25);
                a {
                  color: #fff;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                &:hover {
                  opacity: 1;
                }
              }
            }
            .audit-report {
              margin-top: px2vw(30);
              a {
                display: block;
                height: px2vw(30);
                line-height: px2vw(30);
                white-space: nowrap;
                position: relative;
                padding-left: px2vw(62);
                color: #657180;
                margin-bottom: 20px;
                transition: all 0.2s;
                font-size: px2vw(24);
                &:hover {
                  color: #5f9afd;
                }
                img {
                  width: px2vw(30);
                  height: px2vw(38);
                  position: absolute;
                  left: 0;
                  top: px2vw(-2);
                }
              }
            }
            .pre-img {
              width: px2vw(30);
              height: px2vw(30);
              position: absolute;
              left: 0;
              top: px2vw(8);
            }
          }
        }
        .credible-r {
          width: px2vw(320);
          height: px2vw(320);
          position: relative;
          .credible-c {
            position: absolute;
            width: 100%;
            height: 100%;
            left: px2vw(-30);
            top: px2vw(-9);
          }
          .credible-pane {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .business {
      .business-content {
        .business-text {
          max-width: px2vw(660);
          margin: px2vw(21) auto 0;
          line-height: px2vw(52);
          color: #657180;
          text-align: center;
          font-size: px2vw(26);
        }
        ul {
          display: flex;
          justify-content: space-between;
          margin-top: px2vw(50);
          height: px2vw(360);
          padding: 0 px2vw(80);
          li {
            img {
              margin: 0 auto;
              display: block;
              width: px2vw(140);
              height: px2vw(140);
            }
            span {
              display: block;
              margin-top: px2vw(34);
              text-align: center;
              font-size: px2vw(30);
              color: #182230;
            }
          }
        }
      }
    }
    .global-cny {
      margin-bottom: px2vw(64);
      background-position: center 0;
      background-size: px2vw(1414) auto;
      box-sizing: border-box;
      background-position: center px2vw(110);
      background-size: px2vw(750) auto;
      .global-cny-wrap {
        .global-cny-content {
          padding: px2vw(60) 0 0 0;
          .global-cny-l {
            width: px2vw(320);
            height: px2vw(320);
            position: relative;
            margin: 0 auto px2vw(62);
            .global-cny-pane {
              width: 100%;
              height: 100%;
            }
          }
          .global-cny-r {
            max-width: px2vw(630);
            margin: px2vw(23) auto 0;
            .sub-title {
              height: px2vw(50);
              font-size: px2vw(36);
              line-height: px2vw(50);
              color: #182230;
              margin-bottom: px2vw(28);
            }
            p {
              margin-bottom: px2vw(38);
              padding-left: px2vw(48);
              box-sizing: border-box;
              position: relative;
              font-size: px2vw(26);
              font-weight: 500;
              line-height: px2vw(44);
              color: #657180;
              img {
                width: px2vw(26);
                height: px2vw(26);
                position: absolute;
                left: 0;
                top: px2vw(8);
              }
            }
          }
        }
      }
    }
    .token-info {
      margin-top: px2vw(50);
      margin-bottom: px2vw(10);
      .token-info-wrap {
        overflow: hidden;
        .token-box {
          position: relative;
          top: 0;
          width: px2vw(660);
          height: px2vw(580);
          border-radius: 8px;
          box-shadow: 0 10px 30px 0 #f5f5f5;
          border: solid 1px #eaebee;
          background-color: #ffffff;
          box-sizing: border-box;
          transition: all 0.2s ease-in-out;
          margin: px2vw(40) auto;
          padding: px2vw(91) 0 px2vw(95) 0;
          dl {
            display: flex;
            padding-left: px2vw(122);
            img {
              width: px2vw(120);
              height: px2vw(120);
            }
            dd {
              margin-left: px2vw(30);
              h3 {
                height: px2vw(50);
                font-size: px2vw(36);
                color: #182230;
                line-height: px2vw(50);
                font-weight: normal;
                white-space: nowrap;
              }
              p {
                height: px2vw(30);
                font-size: px2vw(24);
                line-height: px2vw(30);
                color: #333333;
                margin-top: px2vw(15);
              }
            }
          }
          .token-info-des {
            display: flex;
            justify-content: space-between;
            padding: 0 px2vw(61);
            margin-top: px2vw(80);
            img {
              width: px2vw(88);
              height: px2vw(88);
              display: block;
              margin: 0 auto;
            }
            span {
              display: flex;
              width: px2vw(160);
              text-align: center;
              margin-top: px2vw(20);
              line-height: px2vw(40);
              color: #5f9afd;
              font-size: px2vw(26);
            }
          }
        }
      }
    }
    .footer {
      background-color: #182230;
      height: px2vw(204);
      position: relative;
      .footer-c {
        padding: px2vw(25) px2vw(40) px2vw(30) px2vw(42);
        border-bottom: px2vw(1) solid #373e49;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: px2vw(116);
        box-sizing: border-box;

        .logo {
          width: px2vw(157);
          position: relative;
          top: px2vw(8);
          img {
            width: 100%;
            height: 100%;
          }
        }
        .contact-us {
          display: flex;
          align-items: center;
          .contact-btn {
            width: px2vw(162);
            height: px2vw(50);
            border-radius: px2vw(50);
            background-color: #717985;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: px2vw(24);
            cursor: pointer;
            margin-right: px2vw(20);
          }
          .contact-info {
            display: flex;
            justify-content: flex-end;
            li {
              width: px2vw(40);
              height: px2vw(40);
              border-radius: 50%;
              overflow: hidden;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .copyright {
          position: absolute;
          left: 0;
          bottom: 0;
          text-align: center;
          height: px2vw(88);
          line-height: px2vw(88);
          color: #7e859b;
          font-size: px2vw(24);
          font-weight: 500;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

